<template>
  <div class="weaper">
    <div class="company"></div>
    <div class="introduce">
      <div class="icon"></div>
      <div class="content">
        <div class="title-en">
          <p class="middle-line"></p>
          GROUP INTRODUCTION
        </div>
        <div class="title-zh">集团介绍</div>
        <div class="ballon">
          巴龙国际集团创建于1991年，现已发展成为涵盖金融、国贸、平台经济、冷链、食品、服饰、建设为一体的综合性企业集团。巴龙国际集团遵循“先生存，后发展，发展中规范，规范中提升”的企业精神，发展成为拥有总资产达到50亿多元，年实现销售收入30多亿元，注册资金10亿元的国际集团。2001年8月，座落于青岛市香港中路国际金融中心的集团大厦落成，设有北京、上海、济南、杭州、大连、黑龙江、吉林、江西、湖北等几十家分公司。集团积极拓展海外市场，已与十多家跨国企业建立合作关系，产品远销美国、德国、意大利、日本、韩国等20多个国家和地区。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "company"
}
</script>

<style lang="scss" scoped>
.weaper {
  display: flex;
  justify-content: center;
  padding: 108px 0;

  .company {
    width: 600px;
    height: 480px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/company.png") no-repeat;
    background-size: 100% 100%;
    z-index: 2;
  }

  .introduce {
    width: 632px;
    height: 480px;
    background: #F5F5F5;
    margin-left: -32px;
    margin-top: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      right: -20px;
      top: -20px;
      width: 180px;
      height: 180px;
      background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/icon.png") no-repeat;
      background-size: 100% 100%;
    }

    .content {
      width: 528px;
      height: 320px;
      margin-left: 30px;

      .title-en {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #C5A074;
        line-height: 24px;
        display: flex;
        align-items: center;
        padding-bottom: 20px;

        .middle-line {
          width: 64px;
          height: 2px;
          background: #C5A074;
          margin-right: 5px;
        }
      }

      .title-zh {
        font-size: 48px;
        font-family: Noto Serif SC-SemiBold, Noto Serif SC;
        font-weight: 600;
        color: #434343;
        line-height: 72px;
      }

      .ballon {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #434343;
        line-height: 24px;
        margin-top: 20px;
        text-align: justify;
      }
    }
  }
}
</style>
