<template>
  <div class="weaper">
    <div class="banner">
      <div class="bigtitle">
        <h1>产业金融</h1>
        <h2>INDUSTRIAL FINANCE</h2>

        <p class="rouetermsg">首页>产业金融</p>
      </div>
    </div>
    <div class="content">
      <div class="content-msg">
        <div class="title">
          <span class="gun"></span><span class="text">INDUSTRIAL FINANCE</span>
        </div>
        <div class="msg">
          <h1>产业金融</h1>
          <p>
            平台以大股东身份投资青岛、济南、烟台、潍坊、长岛5家农商银行，拥有6000亿金融资产。
            同时，吸引各大银行入驻，为平台客户提供优越的各项金融服务，打包上市，实现企业可持续发展。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    position: relative;
    width: 100%;
    height: 404px;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(16).png')
      no-repeat;
    background-size: 100% 100%;
    .bigtitle {
      position: absolute;
      left: 360px;
      bottom: 24px;
      h1 {
        width: 176px;
        height: 66px;
        font-size: 44px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 66px;
      }
      h2 {
        width: 266px;
        height: 36px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
      .rouetermsg {
        margin-top: 96px;
        height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 80px auto 240px;
    height: 400px;
    position: relative;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/img.png')
      no-repeat;
    .content-msg {
      position: absolute;
      left: 204px;
      bottom: -156px;
      width: 792px;
      height: 256px;
      background: #f5f5f5;
      padding: 40px 36px;
      .title {
        .gun {
          vertical-align: middle;
          display: inline-block;
          width: 64px;
          height: 2px;
          background: #c5a074;
          margin-right: 8px;
        }
        .text {
          vertical-align: middle;
          width: 170px;
          height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #c5a074;
          line-height: 24px;
        }
      }
      .msg {
        h1 {
          height: 72px;
          font-size: 48px;
          font-family: Noto Serif SC-SemiBold, Noto Serif SC;
          font-weight: 600;
          color: #434343;
          line-height: 72px;
          margin: 16px 0;
        }
        p {
          height: 48px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 24px;
        }
      }
    }
  }
  // .nav {
  //   width: 100%;
  //   height: 72px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #FAFAFA;

  //   .nav-btn {
  //     font-size: 24px;
  //     font-family: PingFang SC-Regular, PingFang SC;
  //     font-weight: 400;
  //     color: #434343;
  //     line-height: 36px;
  //     padding: 18px 48px;
  //     margin: 0 20px;
  //     cursor: pointer;
  //     white-space: nowrap;
  //   }
  // }

  // .btn-active {
  //   color: #C5A074 !important;
  //   border-bottom: 3px solid #C5A074;
  // }
}
</style>
